<template>
  <moe-page title="app版本">
    <!-- 筛选信息 -->
      <moe-inquire @search="appVersionSearch">
        <el-form-item label="版本ID">
          <el-input :value="appVersionParams.id" placeholder="输入版本ID" @input="(value) => appVersionParams.id = value.replace(/^\.+|[^\d]/g,'')" clearable />
        </el-form-item>
        <el-form-item label="应用平台">
          <moe-select v-model="appVersionParams.platform" type="platform" placeholder="选择应用平台" />
        </el-form-item>
        <el-form-item label="版本号">
          <el-input v-model="appVersionParams.version" placeholder="输入版本号" clearable />
        </el-form-item>
      </moe-inquire>
    <moe-table ref="appVersionTable" url="/version/page" :params="appVersionParams" :number-show="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$router.push('/appVersion/add')">添加应用版本</el-button>
      </template>

      <el-table-column prop="id" label="版本ID"></el-table-column>

      <el-table-column prop="platform" label="平台" />
      <el-table-column prop="version" label="版本号" />
      <el-table-column label="创建时间">
        <template slot-scope="{ row }">
          <div>{{ $moe_time.getTimeStamp(row.createTime) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template slot-scope="{ row }">
          <div>{{ $moe_time.getTimeStamp(row.updateTime) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="是否启用">
        <template slot-scope="{ row }">
          <!-- <moe-tag type="danger" v-if="row.status == 2"><i class="el-icon-unlock" />隐藏</moe-tag>
          <moe-tag type="success" v-else-if="row.status == 1"><i class="el-icon-lock" />展示</moe-tag>
          <moe-tag type="info" v-else>无效</moe-tag> -->
          {{ row.status == 1 ? '启用' : '禁用' }}
        </template>
      </el-table-column>
      <el-table-column label="Android下载地址" prop="url" :show-overflow-tooltip="true" />

      <el-table-column label="操作" width="300" fixed="right">
        <div class="moe-table__btns df jufs" style="width: 300px;" slot-scope="{ row }">
          <el-button v-if="row.status == 2" plain type="primary" size="mini" icon="el-icon-unlock" @click="handleShelf(row, 1, '启用')">启用</el-button>
          <el-button v-else-if="row.status == 1" plain type="info" size="mini" icon="el-icon-lock" @click="handleShelf(row, 2, '禁用')">禁用</el-button>
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$router.push(`/appVersion/amend?id=${row.id}`)">编辑</el-button>
          <el-button v-if="row.url" type="primary" size="mini" icon="el-icon-download" @click="handleDownload(row.url)">下载</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'AppVersion',
  data() {
    let appVersionParams = {
      id: '',
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      platform: '',
      version: '',
    }
    return {
      appVersionParams
    }
  },
  methods: {
    appVersionSearch(isSearch) {
      if (!isSearch) {
        this.appVersionParams = {
          id: '',
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
          platform: '',
          version: '',
        };
      }

      this.$refs['appVersionTable'].searchData();
    },
    /** 下载Android地址 */
    handleDownload(url) {
      var downloadElement = document.createElement('a');
      downloadElement.href = url
      downloadElement.download = this.apptNumrSuccName
      downloadElement.click()
      downloadElement.remove()
    },
    /** 应用版本上下线 */
    handleShelf({ id }, status, text) {
      this.$moe_layer.confirm(`您确定要“${text}”当前选项吗？`, () => {
        this.$moe_api.VERSION_API.versionShelf({ id, status }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('操作成功');
            this.$refs['appVersionTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
  }
}
</script>